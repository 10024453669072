<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { fetchSettings } from "./api";
export default {
  page: {
    title: "Paramètres d'entreprise",
    meta: [{ name: "description", content: appConfig.description }],
  },
  import : { fetchSettings },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Paramètres d'entreprise",

      companySettingsForm: {
        company_name: "",
        company_phone: "",
        company_email: "",
        company_website: "",
        company_adr: "",
        company_logo: null,
      },
      imagePreview: null,
      showPreview: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/settings",
        },
        {
          text: "Paramètres d'entreprise",
          active: true,
        },
      ],
    };
  },

  validations: {
    companySettingsForm: {
      company_name: { required },
      // : { required }
    },
  },

  mounted() {
    this.populateCompanySettings();
  },

  methods: {
    showCompanyLogo(configs){
      this.imagePreview = configs.company_logo;
      this.showPreview = true;
    },
    populateCompanySettings(){
      fetchSettings()
        .then(({ data }) => {
          var configs = data.original.data;
          this.companySettingsForm.company_name = configs.company_name;
          this.companySettingsForm.company_phone = configs.company_phone;
          this.companySettingsForm.company_email = configs.company_email;
          this.companySettingsForm.company_website = configs.company_website;
          this.companySettingsForm.company_adr = configs.company_adr;
          if(configs.company_logo != null){
            this.showCompanyLogo(configs);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    onLogoSelectChange(event) {
      this.companySettingsForm.company_logo = event.target.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this),
        false
      );

      if (this.companySettingsForm.company_logo) {
        if (
          /\.(jpe?g|png|gif)$/i.test(this.companySettingsForm.company_logo.name)
        ) {
          reader.readAsDataURL(this.companySettingsForm.company_logo);
        }
      }
    },
    
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      let formData = new FormData();
      formData.append('company_name', this.companySettingsForm.company_name);
      formData.append('company_phone', this.companySettingsForm.company_phone);
      formData.append('company_email', this.companySettingsForm.company_email);
      formData.append('company_website', this.companySettingsForm.company_website);
      formData.append('company_adr', this.companySettingsForm.company_adr);
      formData.append('company_logo', this.companySettingsForm.company_logo);
      this.$http
        .post("/system/settings/store", formData)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              // setTimeout(function () {
              //   router.push({ name: "base.contracts.contracts.index" });
              // }, 3000);
              this.populateCompanySettings();
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form
              class="needs-validation"
              @submit.prevent="formSubmit"
              enctype="multipart/form-data"
            >
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity"
                          >Nom d'entreprise *</label
                        >
                        <input type="text" v-model="companySettingsForm.company_name" class="form-control"/>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Email *</label>
                        <input type="text" v-model="companySettingsForm.company_email" class="form-control"/>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="formrow-password-input">N° Telephone</label>
                        <input type="text" v-model="companySettingsForm.company_phone" class="form-control"/>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="form-group">
                        <label for="formrow-inputCity">Site web</label>
                        <input type="text" v-model="companySettingsForm.company_website" class="form-control"/>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="formrow-inputCity">Adresse *</label>
                        <textarea
                          v-model="companySettingsForm.company_adr"
                          class="form-control"
                          cols="30"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Image/Logo *</label>
                    <input
                      class="form-control"
                      type="file"
                      @change="onLogoSelectChange"
                      accept="image/*"
                    />
                    <img
                      v-bind:src="imagePreview"
                      width="100"
                      height="100"
                      v-show="showPreview"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  :disabled="$v.companySettingsForm.$invalid"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
